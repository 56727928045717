
import { mdiAllInclusive } from "@mdi/js"
export default {
    name: "JobInterestsStatistics",
    components: {
        OabatDashboardGraph: () =>
            import("@/components/statistics/OabatDashboardGraph"),
        DashboardStatisticsTable: () =>
            import("@/components/statistics/DashboardStatisticsTable"),
    },
    emits: ["refresh"],
    props: {
        statisticsData: {
            type: Object,
            default: null,
        },
        basicChartOptions: {
            type: Object,
            default: null,
        },
    },
    data: function () {
        return {
            mdiAllInclusive,
        }
    },
    computed: {
        tableEntries() {
            if (!this.statisticsData) {
                return []
            }
            return [
                {
                    label: "JobInterests",
                    icon: mdiAllInclusive,
                    value:
                        this.statisticsData.latestRecord?.job_interests +
                        " (vor 7 Tagen: " +
                        this.statisticsData.lastWeekRecord?.job_interests +
                        ")",
                },
            ]
        },
        chartOptions() {
            let options = this.basicChartOptions
            options.title.text = "JobInterest-Statistik"
            options.color = ["#d679c7"]
            return options
        },
        graphData() {
            let series = []
            if (this.statisticsData) {
                series.push({ name: "JobInterests", data: [] })
                for (const value of Object.values(
                    this.statisticsData.statistics
                )) {
                    series[0].data.push(value.job_interests)
                }
            }
            return series
        },
    },
    methods: {
        refreshData(params) {
            this.$emit("refresh", params)
        },
    },
}
